<template>
    <v-container>
        <!-- 表格部分，添加多選功能 -->
        <v-data-table :headers="headers" :items="botList" v-if="!isNotificationMode">
            <!-- eslint-disable vue/valid-v-slot -->
            <template v-slot:item.actions="{ item }">
                <v-btn color="primary" size="small" class="mr-2" @click="handleCheck(item)">
                    查看
                </v-btn>
                <v-btn color="info" size="small" @click="openEditDialog(item)">
                    編輯
                </v-btn>
            </template>
        </v-data-table>

        <!-- 發送訊息模式的表格 -->
        <v-data-table v-model="selected" :headers="headers" :items="botList" show-select v-if="isNotificationMode"
            item-value="ID" return-object>
        </v-data-table>

        <!-- 主要的 Speed Dial -->
        <div class="speed-dial-container" v-if="!isNotificationMode">
            <v-speed-dial transition="scale-transition">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-fab v-bind="activatorProps" size="large" color="primary" icon="mdi-plus"></v-fab>
                </template>

                <v-tooltip text="發送通知">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" key="1" color="indigo" icon="mdi-message-text"
                            @click="enterNotificationMode"></v-btn>
                    </template>
                </v-tooltip>

                <v-tooltip text="新增帳號">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" key="2" color="green" icon="mdi-plus-circle"
                            @click="openNewAccountDialog"></v-btn>
                    </template>
                </v-tooltip>
            </v-speed-dial>
        </div>

        <!-- 發送訊息模式的 Speed Dial -->
        <div class="speed-dial-container" v-if="isNotificationMode">
            <v-speed-dial transition="scale-transition">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-fab v-bind="activatorProps" size="large" :color="selected.length > 0 ? 'success' : 'error'"
                        :icon="selected.length > 0 ? 'mdi-send' : 'mdi-close'"
                        @click="selected.length > 0 ? openMessageDialog() : exitNotificationMode()"></v-fab>
                </template>
            </v-speed-dial>
        </div>

        <!-- 發送訊息 Dialog -->
        <v-dialog v-model="messageDialog" max-width="500px" :persistent="isLoading">
            <v-card :loading="isLoading">
                <v-card-title class="d-flex align-center">
                    <span>發送訊息</span>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" size="small" @click="messageDialog = false" :disabled="isLoading"></v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="mb-4">已選擇 {{ selected.length }} 個目標</div>
                    <v-textarea v-model="messageContent" label="訊息內容" rows="4"></v-textarea>
                    <v-file-input v-model="messageImage" label="上傳圖片" accept="image/*" show-size
                        prepend-icon="mdi-camera"></v-file-input>
                    <!-- 圖片預覽 -->
                    <v-img v-if="messageImagePreview" :src="messageImagePreview" max-height="200" contain
                        class="mt-2"></v-img>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="sendMessage" :loading="isLoading" :disabled="isLoading">
                        發送
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 發送通知 Dialog -->
        <v-dialog v-model="notificationDialog" max-width="500px">
            <v-card>
                <v-card-title class="d-flex align-center">
                    <span>發送通知</span>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" size="small" @click="notificationDialog = false"></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-textarea v-model="notificationContent" label="通知內容" rows="4"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="sendNotification">
                        發送
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 新增帳號 Dialog -->
        <v-dialog v-model="newAccountDialog" max-width="600px" :persistent="isLoading">
            <v-card :loading="isLoading">
                <v-card-title class="d-flex align-center">
                    <span>新增 Bot</span>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" size="small" @click="newAccountDialog = false"
                        :disabled="isLoading"></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="newBotData.Name" label="Name" class="mb-4" required></v-text-field>
                    <v-text-field v-model="newBotData.TgToken" label="TgToken"></v-text-field>
                    <v-textarea v-model="newBotData.Content" label="Content"></v-textarea>
                    <v-file-input label="Image" accept="image/*" @change="onNewBotImageChange"></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="createNewBot" :loading="isLoading" :disabled="isLoading">
                        創建
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 編輯 Dialog -->
        <v-dialog v-model="editDialog" max-width="600px" :persistent="isLoading">
            <v-card :loading="isLoading">
                <v-card-title class="d-flex align-center">
                    <span>編輯 Bot 設定</span>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" size="small" @click="editDialog = false" :disabled="isLoading"></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="mainData.Name" label="Name" class="mb-4" required></v-text-field>
                    <v-text-field v-model="mainData.TgToken" label="TgToken"></v-text-field>
                    <v-textarea v-model="mainData.Content" label="Content"></v-textarea>
                    <v-file-input label="Image" accept="image/*" @change="onImageChange"></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="updateMainData" :loading="isLoading" :disabled="isLoading">
                        更新
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style scoped>
.speed-dial-container {
    position: fixed;
    bottom: 3%;
    right: 3%;
    z-index: 999;
}
</style>

<script>
import axios from 'axios'
import Cookies from 'js-cookie';

export default {
    name: 'HomePage',
    inject: ['API_URL'],
    data() {
        return {
            AccountID: Cookies.get('AccountID'),
            headers: [
                { title: 'Name', key: 'Name' },
                { title: '操作', key: 'actions', sortable: false }
            ],
            botList: [],
            editDialog: false,
            currentBotID: null,
            mainData: {
                Name: '',     // 添加 Name 字段
                Content: '',
                TgToken: '',
                Image: null
            },
            fab: false,
            notificationDialog: false,
            newAccountDialog: false,
            notificationContent: '',
            newAccount: {
                username: '',
                password: ''
            },
            centerFab: false, // 新增的中央按鈕控制變量
            newBotData: {
                Name: '',
                Content: '',
                TgToken: '',
                Image: null
            },
            isNotificationMode: false,
            selected: [],
            messageDialog: false,
            messageContent: '',
            itemsPerPage: 10,
            selectStrategy: 'independent', // 防止點擊行時自動選中
            messageImage: null,
            messageImagePreview: null,
            isLoading: false, // 添加 loading 狀態
        }
    },
    mounted() {
        this.fetchBotList()
    },
    methods: {
        async fetchBotList() {
            try {
                const formData = new FormData()
                formData.append('AccountID', this.AccountID)

                const response = await axios.post(this.API_URL + '/API/getBotList.php', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                if (!Array.isArray(response.data)) {
                    this.botList = [response.data];
                } else {
                    this.botList = response.data;
                }
            } catch (error) {
                console.error('獲取Bot列表失敗:', error)
                this.botList = [];
            }
        },
        handleCheck(item) {
            localStorage.setItem('CurrentBotMainID', item.ID)
            this.$router.push('/main')
        },
        async openEditDialog(item) {
            this.currentBotID = item.ID;
            this.editDialog = true;
            await this.fetchBotMainData(item.ID);
        },
        async fetchBotMainData(botID) {
            try {
                const formData = new FormData();
                formData.append('BotMainID', botID);
                const response = await axios.post(this.API_URL + '/API/BotMain.php', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
                this.mainData.Name = response.data.Name;      // 添加 Name
                this.mainData.Content = response.data.Content;
                this.mainData.TgToken = response.data.TgToken;
            } catch (error) {
                console.error('獲取Bot資料失敗:', error);
            }
        },
        onImageChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.mainData.Image = file;
            } else {
                this.mainData.Image = null;
            }
        },
        async updateMainData() {
            if (this.isLoading) return;
            this.isLoading = true;

            try {
                const formData = new FormData();
                formData.append('BotMainID', this.currentBotID);
                formData.append('Name', this.mainData.Name);  // 添加 Name
                formData.append('Content', this.mainData.Content);
                formData.append('TgToken', this.mainData.TgToken);

                if (this.mainData.Image) {
                    formData.append('Image', this.mainData.Image, this.mainData.Image.name);
                }

                const response = await axios.post(this.API_URL + '/API/SaveBotMain.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.data.success) {
                    alert('更新成功');
                    this.editDialog = false;
                    await this.fetchBotList(); // 重新獲取列表
                } else {
                    alert('更新失敗: ' + response.data.message);
                }
            } catch (error) {
                console.error('更新失敗:', error);
                alert('更新失敗');
            } finally {
                this.isLoading = false;
            }
        },
        openNotificationDialog() {
            this.notificationDialog = true;
        },
        openNewAccountDialog() {
            this.newBotData = {
                Name: '',
                Content: '',
                TgToken: '',
                Image: null
            };
            this.newAccountDialog = true;
        },
        async sendNotification() {
            try {
                const formData = new FormData();
                formData.append('content', this.notificationContent);

                const response = await axios.post(this.API_URL + '/API/sendNotification.php', formData);

                if (response.data.success) {
                    alert('通知發送成功');
                    this.notificationDialog = false;
                    this.notificationContent = '';
                } else {
                    alert('發送失敗: ' + response.data.message);
                }
            } catch (error) {
                console.error('發送失敗:', error);
                alert('發送失敗');
            }
        },

        onNewBotImageChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.newBotData.Image = file;
            } else {
                this.newBotData.Image = null;
            }
        },
        async createNewBot() {
            if (this.isLoading) return;
            this.isLoading = true;

            try {
                const formData = new FormData();
                formData.append('AccountID', this.AccountID);
                formData.append('Name', this.newBotData.Name);
                formData.append('Content', this.newBotData.Content);
                formData.append('TgToken', this.newBotData.TgToken);

                if (this.newBotData.Image) {
                    formData.append('Image', this.newBotData.Image, this.newBotData.Image.name);
                }

                const response = await axios.post(this.API_URL + '/API/CreateBot.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.success) {
                    alert('Bot 創建成功');
                    this.newAccountDialog = false;
                    this.newBotData = {
                        Name: '',
                        Content: '',
                        TgToken: '',
                        Image: null
                    };
                    await this.fetchBotList();
                } else {
                    alert('創建失敗: ' + response.data.message);
                }
            } catch (error) {
                console.error('創建失敗:', error);
                alert('創建失敗');
            } finally {
                this.isLoading = false;
            }
        },
        enterNotificationMode() {
            this.isNotificationMode = true;
            this.selected = [];
        },

        exitNotificationMode() {
            this.isNotificationMode = false;
            this.selected = [];
        },

        openMessageDialog() {
            if (this.selected.length > 0) {
                this.messageDialog = true;
                this.messageImage = null;
                this.messageImagePreview = null;
            }
        },

        async sendMessage() {
            if (this.isLoading) return; // 防止重複點擊
            this.isLoading = true;

            try {
                const formData = new FormData();
                formData.append('content', this.messageContent);
                const selectedIds = this.selected.map(item => item.ID);
                formData.append('targets', JSON.stringify(selectedIds));

                if (this.messageImage) {
                    formData.append('Image', this.messageImage);
                }

                const response = await axios.post(this.API_URL + '/API/sendMessage.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.success) {
                    alert('訊息發送成功');
                    this.messageDialog = false;
                    this.messageContent = '';
                    this.messageImage = null;
                    this.messageImagePreview = null;
                    this.exitNotificationMode();
                } else {
                    alert('發送失敗: ' + response.data.message);
                }
            } catch (error) {
                console.error('發送失敗:', error);
                alert('發送失敗');
            } finally {
                this.isLoading = false;
            }
        }
    },
    watch: {
        messageImage(file) {
            if (file) {
                this.messageImagePreview = URL.createObjectURL(file);
            } else {
                this.messageImagePreview = null;
            }
        }
    },
    beforeUnmount() {
        if (this.messageImagePreview) {
            URL.revokeObjectURL(this.messageImagePreview);
        }
    }
}
</script>
