<template>
    <v-sheet class="bg-deep-purple pa-12" rounded style="height: 100%;">
        <v-card class="mx-auto px-6 py-8" max-width="344">
            <h2 class="text-center">
                Login
            </h2>
            <br>

            <v-form v-model="form" @submit.prevent="onSubmit">
                <v-text-field v-model="formData.Account" :readonly="loading" :rules="[validateNoSpecialChars]"
                    class="mb-2" clearable label="Account"></v-text-field>
                <v-text-field type="password" v-model="formData.Password" :readonly="loading"
                    :rules="[validateNoSpecialChars]" clearable label="Password"
                    placeholder="Enter your password"></v-text-field>

                <br>
                <v-card class="mb-12" color="surface-variant" variant="tonal" v-if="errorText != ''">
                    <v-card-text class="text-medium-emphasis text-caption">
                        {{ errorText }}
                    </v-card-text>

                </v-card>
                <v-btn :disabled="!form" :loading="loading" block color="success" size="large" type="submit"
                    variant="elevated">
                    Login
                </v-btn>
                <br>

            </v-form>
        </v-card>
    </v-sheet>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
    inject: ['API_URL'],
    data: () => ({
        form: false,
        formData: {
            Account: '',
            Password: '',
        },
        loading: false,
        errorText: ''
    }),

    methods: {
        onSubmit() {
            this.errorText = '';
            this.loading = true;
            axios.post(this.API_URL + '/API/Login.php', this.formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    this.loading = false;

                    if (response.data.Status) {
                        console.log(response.data)
                        // 使用Cookies替代localStorage
                        Cookies.set('AccountID', response.data.ID, { expires: 7 }); // 设置7天过期
                        Cookies.set('TgToken', response.data.TgToken, { expires: 7 });
                        window.location.href = "/";
                    } else {
                        this.errorText = response.data.message;
                    }

                })
                .catch(error => {
                    console.error('Error:', error);
                });

        },
        validateNoSpecialChars(value) {
            // 使用正则表达式检查输入的值是否包含特殊字符
            const pattern = /^[a-zA-Z0-9_.]*$/;
            return pattern.test(value) || 'Please enter a valid value without special characters.';
        },
        navigateTo(route) {
            this.$router.push(route);
        },
    },

}
</script>
