<template>
    <v-container>
        <!-- 搜尋條件 -->
        <v-card class="mb-4">
            <v-card-text>
                <v-row align="center">
                    <v-col cols="12" sm="4">
                        <v-select v-model="selectedBot" :items="botList" item-title="Name" item-value="ID"
                            label="選擇 Bot" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                            offset-y>
                            <template v-slot:activator="{ props }">
                                <v-text-field v-model="searchMonth" label="選擇月份" prepend-icon="mdi-calendar" readonly
                                    v-bind="props"></v-text-field>
                            </template>
                            <v-date-picker v-model="searchMonth" type="month" no-title
                                @update:model-value="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-btn color="primary" @click="searchMembers" :loading="isLoading"
                            :disabled="isLoading || !searchMonth || !selectedBot">
                            搜尋
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- 會員列表 -->
        <v-data-table :headers="headers" :items="members" :loading="isLoading" loading-text="載入中..." no-data-text="無資料"
            :items-per-page="10">
            <!-- eslint-disable vue/valid-v-slot -->
            <template v-slot:progress>
                <v-progress-linear color="primary" indeterminate></v-progress-linear>
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip :color="getStatusColor(item.status)" :text="getStatusText(item.status)"></v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn size="small" color="primary" @click="viewMemberDetail(item)">
                    詳細
                </v-btn>
            </template>
        </v-data-table>

        <!-- 會員詳細資料 Dialog -->
        <v-dialog v-model="detailDialog" max-width="600px">
            <v-card>
                <v-card-title class="d-flex align-center">
                    <span>會員詳細資料</span>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" size="small" @click="detailDialog = false"></v-btn>
                </v-card-title>
                <v-card-text v-if="selectedMember">
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>會員ID</v-list-item-title>
                            <v-list-item-subtitle>{{ selectedMember.id }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>名稱</v-list-item-title>
                            <v-list-item-subtitle>{{ selectedMember.name }}</v-list-item-subtitle>
                        </v-list-item>
                        <!-- 其他會員資料... -->
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
    name: 'MemberPage',
    inject: ['API_URL'],
    data() {
        return {
            searchMonth: new Date().toISOString().substr(0, 7),
            selectedBot: null,
            botList: [],
            menu: false,
            isLoading: false,
            headers: [
                { title: '會員ID', key: 'MemberID', sortable: true },
                { title: '名稱', key: 'Name', sortable: true },
                { title: '电话', key: 'Phone', sortable: true },
                { title: '加入日期', key: 'DBTime', sortable: true },
                { title: '狀態', key: 'Status', sortable: true }
            ],
            members: [],
            detailDialog: false,
            selectedMember: null
        }
    },
    mounted() {
        this.fetchBotList();
    },
    methods: {
        async fetchBotList() {
            try {
                const formData = new FormData();
                formData.append('AccountID', Cookies.get('AccountID'));

                const response = await axios.post(this.API_URL + '/API/getBotList.php', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                if (!Array.isArray(response.data)) {
                    this.botList = [response.data];
                } else {
                    this.botList = response.data;
                }
            } catch (error) {
                this.botList = [];
            }
        },

        async searchMembers() {
            if (!this.searchMonth || !this.selectedBot) return;

            this.isLoading = true;
            try {
                const formData = new FormData();
                formData.append('month', this.searchMonth);
                formData.append('BotMainID', this.selectedBot);

                const response = await axios.post(this.API_URL + '/API/getMembers.php', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
                this.members = response.data;
            } catch (error) {
                alert('獲取會員資料失敗');
            } finally {
                this.isLoading = false;
            }
        },

        getStatusColor(status) {
            const colors = {
                active: 'success',
                inactive: 'error',
                pending: 'warning'
            };
            return colors[status] || 'grey';
        },

        getStatusText(status) {
            const texts = {
                active: '使用中',
                inactive: '已停用',
                pending: '待審核'
            };
            return texts[status] || status;
        },

        viewMemberDetail(item) {
            this.selectedMember = item;
            this.detailDialog = true;
        }
    }
}
</script>
