<template>
  <v-app id="inspire">

    <v-navigation-drawer v-model="drawer">
      <v-list-item color="grey-lighten-4" class="pa-4 text-none-select" :subtitle="AccountType" title="test">
        <template v-slot:prepend>
          <v-avatar color="red">
            <v-icon color="white">mdi-account</v-icon>
          </v-avatar>
        </template>

      </v-list-item>

      <v-list>
        <v-list-item v-for="[icon, text, link] in filteredLinks" :key="icon" :prepend-icon="icon" :title="text" link
          @click="Link(link)"></v-list-item>

      </v-list>
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item prepend-icon="mdi-logout" title="Logout" value="Logout" @click="Logout"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="bar">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>
        {{ FuncName }}
      </v-app-bar-title>

      <!-- <v-app-bar-title class="pa-4 text-right">
        <v-chip class="mr-2">
          Month:{{ Month }}
        </v-chip>
        <v-chip class="mr-2">
          Website:{{ Website }}
        </v-chip>
        <v-btn icon size="small" @click="SettingDialog = true">
          <v-avatar color="brown">
            <span class="mdi mdi-lead-pencil"></span>
          </v-avatar>
        </v-btn>
      </v-app-bar-title> -->
    </v-app-bar>

    <v-main>
      <!-- {{ currentPage }} -->
      <router-view></router-view>

    </v-main>

  </v-app>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  provide: {
    API_URL: 'https://telebot.amoikiss.com'
  },
  data() {
    return {
      WebList: [],
      Website: '',
      SettingDialog: false,
      drawer: null,
      bar: true,
      links: [
        ['mdi-alpha-h-circle-outline', 'Home', '/'],
        ['mdi-alpha-m-circle-outline', 'Member', '/member'],
      ],
      AccountID: Cookies.get('AccountID'),
      TgToken: Cookies.get('TgToken'),
    };
  },
  created() {
    if (this.AccountID == null) {
      this.bar = false;
      this.drawer = false;
      this.Link("Login");
    }
  },

  computed: {

    filteredLinks() {
      return this.links.filter(([icon, text, link]) => {
        icon, link
        return this.AccountType === '1' || text !== 'Admin';
      });
    },
    FuncName() {
      // 根据当前页面的路径动态确定 FuncName 的值
      const currentPagePath = this.$route.path;
      const matchedLink = this.links.find(link => link[2] === currentPagePath);
      return matchedLink ? matchedLink[1] : '';
    },
    websiteOptions() {
      return this.WebList.map(item => item.Name);
    }
  },
  methods: {
    Link(i) {
      this.$router.push(i);
    },
    Logout() {
      Cookies.remove('AccountID');
      Cookies.remove('TgToken');
      window.location.href = "/";
    }
  },
}
</script>

<style>
.Pencil {
  position: fixed !important;
  bottom: 5%;
  right: 2%;
}

.text-none-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */
}
</style>
